import BaseService from "./base.service";

class CustomerService {

    users(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('customers', page, params);
    }

    get(id) {
        return BaseService.apiGetRequest('customers/'+id);
    }
    delete(role) {
		return BaseService.apiPostRequest('customers/delete/', role);
    }
    add(role) {
        return BaseService.apiPostRequest('customers/add', role);
    }
    update(id, role) {
        return BaseService.apiPostRequest('customers/'+id, role);
    }
}
export default new CustomerService();
