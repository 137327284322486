<template>
  <list-content
      v-bind:rows-data="tableData"
      v-bind:columns="tableColumns"
      v-bind:add-label="pageContent.add_label"
      v-bind:title="pageContent.title"
      v-bind:new-uri="pageContent.newUri"
      v-bind:edit-uri="pageContent.editUri"
      v-bind:meta="meta"
      v-bind:show-message="showMessage"
      v-bind:message="message"
      @handlePageChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleSearchData="performSearch"
      @handleDeleteData="handleDelete"
  ></list-content>
</template>

<script>
import CustomerService from "@/api/customers.service";
import ListContent from "@/components/ListContent";
export default {
  name: "Customers",
  components: {ListContent},
  data() {
    return {
      tableColumns: [
        {'key':'name', 'label':'Customer Name', 'sortable':true, 'is_image': false, 'width':'250'},
        {'key':'pname', 'label':'Product Name', 'sortable':true, 'is_image': false, 'width':''},        
        {'key':'price', 'label':'Product Price', 'sortable':true, 'is_image': false, 'width':'200'},
        {'key':'currency', 'label':'Currency', 'sortable':true, 'is_image': false, 'width':'150'},
        {'key':'interval', 'label':'Interval', 'sortable':true, 'is_image': false, 'width':'150'}
      ],
      pageContent: {
        'add_label': 'Add User',
        'title': 'Customers',
        'newUri': '/users/add',        
        'editUri':'/users/edit'
      },
      tableData: [],
      page: 1,
      search: '',
      meta: Object,
      showMessage: false,
      message: {message:'', messageType:'success', effect:'dark'}
    }
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      return CustomerService.users(this.page, this.search).then(response => {
        this.meta = response.data.meta;
        this.tableData = response.data.data;
      });
    },
    performSearch(search) {
      this.search = search.name;
      this.getTableData();
    },
    handleDelete(data) {
      let deleteRow = data.row;
      this.showMessage = false;
      if (data.indexNo >= 0) {
        return CustomerService.delete(deleteRow).then(response => {
          if(response.status === 201) {
            this.message.message = "Customer deleted successfully";
            this.message.messageType = "success";
            this.message.effect = "dark";
            this.showMessage = true;
            this.getTableData();
          }
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    handleSizeChange(val){
      this.getTableData();
    }
  }
}
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
</style>